import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { getErrorMessage } from "@utils/errors";
import { AnalyticsType } from "@graphql/types";

import { AnalyticsFields } from "~/views/settings/analytics-tracking/_lib/context";

import { Typography } from "~/components/Typography";
import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";
import { getAnalyticsIdLabel, getAnalyticsIdPlaceholder } from "../label";


const useStyles = makeStyles(() => ({
    root: {
        padding: 5,
        boxShadow: "none",
        width: 375,
    },
    pixelTitle: {
        padding: "10px",
        fontWeight: 400,
    },
    button: {
        transition: "none",
    },
}));

const urlRegexp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

type AnalyticsValues = {
    analyticsType: AnalyticsType;
    analyticsId?: string;
    analyticsSecret?: string;
    domain?: string;
};

export interface IUpdateCompanyAnalyticsFormProps {
    label: string;
    action: string;
    fields: AnalyticsFields;
    values?: AnalyticsValues;
    onSubmit: (
        // newType: string,
        newAnalyticsId: string,
        newAnalyticsSecret: string,
        newDomain?: string
    ) => Promise<void>;
    onComplete: () => void;
}

export const UpdateCompanyAnalyticsForm: React.FC<IUpdateCompanyAnalyticsFormProps> = ({
    label,
    action,
    fields,
    values,
    onSubmit,
    onComplete,
}) => {
    const styles = useStyles();
    const [idValue, setIdValue] = useState(values?.analyticsId ?? "");
    const [secretValue, setSecretValue] = useState(values?.analyticsSecret ?? "");
    const [domainValue, setDomainValue] = useState(values?.domain ?? "");
    // const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const analyticsType = (values?.analyticsType ?? "").replace("_", " ");

    const handleIdUpdate = (newId: string) => {
        setIdValue(newId || "");
        // setDisabled((newVal || "").length === 0);
        setError("");
    };
    const handleSecretUpdate = (newSecret: string) => {
        setSecretValue(newSecret || "");
        // setDisabled((newVal || "").length === 0);
        setError("");
    };
    const handleDomainUpdate = (newDomain: string) => {
        setDomainValue(newDomain || "");
        // setDisabled((newVal || "").length === 0);
        setError("");
    };

    const handleSubmit = async () => {
        let complete = false;
        if (!loading) {
            if (!idValue) {
                return setError("Measurement ID is required");
            }
            if (domainValue && !urlRegexp.test(domainValue)) {
                return setError("Please enter a valid domain");
            }
            setLoading(true);
            try {
                await onSubmit(idValue, secretValue, domainValue);
                complete = true;
                onComplete();
            } catch (err) {
                setError(getErrorMessage(err));
            } finally {
                if (!complete) setLoading(false);
            }
        }
    };

    return (
        <BaseForm error={error} className={styles.root}>
            <Typography variant="h6" className={styles.pixelTitle}>
                Pixel: {analyticsType}
            </Typography>
            <Input
                label={getAnalyticsIdLabel(analyticsType)}
                placeholder={getAnalyticsIdPlaceholder(analyticsType)}
                value={idValue}
                update={handleIdUpdate}
                id={"analytics-id-update-input"}
            />
            {fields.includes("analyticsSecret") && (
                <Input
                    label={"Analytics Secret"}
                    placeholder={"Enter new Analytics Secret"}
                    value={secretValue}
                    update={handleSecretUpdate}
                    id={"analytics-secret-update-input"}
                />
            )}
            {fields.includes("domain") && (
                <Input
                    label={"Domain"}
                    placeholder={"Enter new domain"}
                    value={domainValue}
                    update={handleDomainUpdate}
                    id={"analytics-domain-update-input"}
                />
            )}
            <Button
                submit
                onClick={handleSubmit}
                label={action}
                loading={loading}
                // disabled={disabled}
                className={styles.button}
            />
        </BaseForm>
    );
};