import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { PrimaryButton } from "~/components/Button/PrimaryButton";
import { AnalyticsFields, useCompanyAnalyticsContext } from "./context";

import AddIcon from "~/assets/vectors/plus.svg";
import { AnalyticsType } from "@graphql/types";

const useStyles = makeStyles(() => ({
    titleContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        width: "100%",
    },
    addMethod: {
        padding: "0 5px",
        boxShadow: "none",
        textAnchor: "middle",
    },
    label: {
        alignmentBaseline: "middle",
        textAnchor: "middle",
        lineHeight: "1.2em",
        padding: `0 5px`,
    },
    icon: {
        width: "1.25em",
        height: "1.25em",
        marginRight: 5,
    },
}));

interface IAnalyticsTitleProps {
    title: string;
    type: AnalyticsType;
    fields: AnalyticsFields;
    readOnly: boolean;
}

export const AnalyticsTitle: React.FC<IAnalyticsTitleProps> = ({ title, type, fields, readOnly }) => {
    const styles = useStyles();
    const { addCompanyAnalytic, showModal } = useCompanyAnalyticsContext();

    const doAddAnalytic = () => {
        showModal({
            label: "Enter Analytics details",
            action: "Add Analytics Pixel",
            fields,
            values: {
                analyticsType: type,
            },
            onSubmit: async (
                newAnalyticsId: string,
                newAnalyticsSecret: string,
                newDomain?: string,
            ) => {
                await addCompanyAnalytic(
                    type,
                    newAnalyticsId,
                    newAnalyticsSecret,
                    newDomain || "",
                );
            },
        });
    };

    return (
        <Box className={styles.titleContainer}>
            <Box display="flex" alignItems="center">{title}</Box>
            <PrimaryButton
                style={{
                    visibility: readOnly ? "hidden" : undefined,
                }}
                classes={{ root: styles.addMethod, label: styles.label }}
                onClick={doAddAnalytic}
            >
                <AddIcon className={styles.icon} />
                {`Add ${title}`}
            </PrimaryButton>
        </Box>
    );
};