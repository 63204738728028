export const getAnalyticsIdLabel = (type: string) => {
    switch (type) {
        case "GOOGLE_A4":
            return "Measurement ID";
        case "GOOGLE_UA":
            return "Tracking ID";
        case "META_PIXEL":
            return "Pixel ID";
        default:
            return "Analytics ID";
    }
};

export const getAnalyticsIdPlaceholder = (type: string) => {
    switch (type) {
        case "GOOGLE_A4":
            return "G-XXXXXXX";
        case "GOOGLE_UA":
            return "UA-XXXXXX-X";
        case "META_PIXEL":
            return "XXXXXXXXXX";
        default:
            return "Analytics ID";
    }
};
