import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Typography } from "~/components/Typography";

const useStyles = makeStyles(() => ({
    rowContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
}));

interface IAnalyticsEmptyProps {
    message: string;
}

export const AnalyticsEmpty: React.FC<IAnalyticsEmptyProps> = ({ message }) => {
    const styles = useStyles();

    return (
        <Box className={styles.rowContainer}>
            <Typography variant="h4">
                {message}
            </Typography>
        </Box>
    );
};